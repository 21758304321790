import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import mealCourseService from '../../api/meal_course_service';


export default function MealCourse() {
    const [meal_courses, setMealCourse] = useState([]);

    const getMealCourseData = async () => {
        try {
            const getData = await mealCourseService.getMealCourses();
            if (getData) {
                getData?.map(data => data.name = data.names[0].name)
                setMealCourse(getData)
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getMealCourseData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-meal-course" className="btn btn-dark">
                    Add Meal Course
                </NavLink>
            </div>
            {meal_courses.length > 0 && < TableWrapper data={meal_courses} link="/view-meal-course" />}
        </div>
    );
}