import React from 'react';
import { useParams } from 'react-router-dom';
import NutrientForm from '../../components/Form/nutrient/update';



export default function ViewNutrient() {
    const { id } = useParams();

    return (
        <div>
            <NutrientForm purpose="Update" id={id} />
        </div>
    )
}