import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import DishService from '../../api/dish_service';
import factorCategoryService from '../../api/factors_category_service';


export default function FactorCategory() {
    const [dishes, setDishes] = useState([]);

    if (dishes.length > 0) {
        dishes.map(data => data.name = data.names[0].name )
    }

    const getdishesData = async () => {
        try {
            const getData = await factorCategoryService.getFactorCategories();
            if (getData) { setDishes(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getdishesData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-factor-category" className="btn btn-dark">
                    Add Factor Category
                </NavLink>
            </div>
            <h2 className='text-center py-4'>Factor Categories</h2>
            {dishes.length > 0 && < TableWrapper data={dishes} link="/view-factor-category" />}
        </div>
    );
}