import React from 'react';
import CreateLGAForm from '../../components/Form/lga/create';


export default function CreateLGA() {
    return (
        <div>
            {/*<CreateDishForm purpose="Create" />*/}
            <CreateLGAForm purpose="Create" />
        </div>
    )
}