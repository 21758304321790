import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateStateForm from '../../components/Form/state/update';



export default function ViewState() {
    const { id } = useParams();

    return (
        <div>
            <UpdateStateForm purpose="Update" id={id} />
        </div>
    )
}