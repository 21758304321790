import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateChefActionForm from '../../components/Form/chef-action/update';


export default function ViewChefAction() {
    const { id } = useParams();

    return (
        <div>
            <UpdateChefActionForm purpose="Update" id={id} />
        </div>
    )
}