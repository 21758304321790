import axios from 'axios';
import { BaseURL } from './service_variables';

const microServices = {
  imageUploadAPI: async (folder, file, previousImageUrl) => {
    console.log("FIle Type corresponds to: ", file && file.type.startsWith("image/"));
    const TOKEN = window.localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', folder); // Send folder information
    formData.append('previous_image_url', previousImageUrl); // Send previous image URL

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/s3/upload-media/`; // Updated URL

    try {
      const response = await axios.post(url, formData, {
        headers: headers,
        withCredentials: false,
      });

      console.log("Response for Image", response)

      return response.data.s3_path; // Return the S3 path directly
    } catch (error) {
      console.error("Image upload failed:", error);
      throw error;
    }
  },
};

export default microServices;