import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateRecipeUnitSchemeForm from '../../components/Form/recipe-unit-scheme/update';



export default function ViewRecipeUnitScheme() {
    const { id } = useParams();

    return (
        <div>
            <UpdateRecipeUnitSchemeForm purpose="Update" id={id} />
        </div>
    )
}