import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import DishService from '../../api/dish_service';


export default function Dishes() {
    const [dishes, setDishes] = useState([]);

    if (dishes.length > 0) {
        dishes.map(data => data.name = data.names[0].name )
    }

    const getdishesData = async () => {
        try {
            const getData = await DishService.getDishes();
            if (getData) { setDishes(getData) }

            console.log(getData)
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getdishesData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-dish" className="btn btn-dark">
                    Add Dish
                </NavLink>
            </div>
            {dishes.length > 0 && < TableWrapper data={dishes} link="/view-dish" />}
        </div>
    );
}