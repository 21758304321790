import React from 'react';

export default function Intro() {
    return(
        <div className="my-5 container">
        <div className="p-5 text-center bg-body-tertiary">
          <div className="container py-5">
            <h1 className="text-body-emphasis">Welcome to Diet Tribe</h1>
            <p className="col-lg-8 mx-auto lead">
              As administrator, you will participate data refinement and input. Thanks for joining this worthy journey!
            </p>
            <small>Use Nav Bar to access your tasks</small>
          </div>
        </div>
      </div>
    )
}
