import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import ethnicityService from '../../api/ethnicity_service';


export default function Ethnicity() {
    const [ethnicity, setEthnicities] = useState([]);

    const getEthnicitiesData = async () => {
        try {
            const getData = await ethnicityService.getEthnicities();
            if (getData) { setEthnicities(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getEthnicitiesData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-ethnicity" className="btn btn-dark">
                    Add Ethnicity
                </NavLink>
            </div>
            <h2 className='text-center py-4'>Ethnicities</h2>
            {ethnicity.length > 0 && < TableWrapper data={ethnicity} link="/view-ethnicity" />}
        </div>
    );
}