import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import ethnicityMealScoreService from '../../../api/ethnicity_meal_score_service';

export default function CreateEthnicityMealScoreForm({ purpose, ethnicity_id, name, meal_id, ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [score, setScore] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");

    useEffect(() => {
        setShowToast(false);
    }, []);


    const handleCreate = async (event) => {
        event.preventDefault();

        setDisabled(true);

        try {
            const createData = await ethnicityMealScoreService.createEthnicityMealScore(description, score, ethnicity_id, meal_id);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Created Successfully</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Score {name}</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} id="score" onChange={(e) => setScore(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Score<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Score is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Describe Relationship</label>
                </div>
                
                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleCreate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}
