import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateLGAForm from '../../components/Form/lga/update';



export default function ViewLGA() {
    const { id } = useParams();

    return (
        <div>
            <UpdateLGAForm purpose="Update" id={id} />
        </div>
    )
}