import React from 'react';
import CreateFactorCategoryForm from '../../components/Form/factors_category/create';


export default function CreateFactorCategory() {
    return (
        <div>
            {/*<CreateDishForm purpose="Create" />*/}
            <CreateFactorCategoryForm purpose="Create" />
        </div>
    )
}