import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import mealTypeService from '../../../api/meal_type';


export default function MealTypeForm({ purpose,
    ...props
}) {
    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const language = "659b6f8f48e3e48cea5bbccf";
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [startHour, setStartHour] = useState('');
    const [startMinute, setStartMinute] = useState('');
    const [endHour, setEndHour] = useState('');
    const [endMinute, setEndMinute] = useState('');

    // Array of numbers for dropdowns
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = ["00", "15", "30", "45"];

    useEffect(() => {
        setShowToast(false);
    }, []);


    const handleCreate = async (event) => {
        event.preventDefault();
        const names = { 'language_id': language, 'name': name }
        const start_time = { hour: parseInt(startHour), minute: parseInt(startMinute) }
        const end_time = { hour: parseInt(endHour), minute: parseInt(endMinute) }

        setDisabled(true);

        try {
            const createData = await mealTypeService.createMealType(names, description, start_time, end_time);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Meal Type Created</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Create Meal Type</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} id="name" onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Meal Type Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Meal Type Description</label>
                </div>

                <div className='row justify-content-between'>
                    <div className="row col-md-4 col-12">
                        <label className="control-label py-2">Select Start Time</label>
                        <div className="form-floating col-m-4 col-6">
                            <select className="form-select" value={startHour} onChange={(e) => setStartHour(e.target.value)}>
                                {hours.map((hour) => (
                                    <option key={hour} value={hour}>{hour}</option>
                                ))}
                            </select>
                            <label className="control-label" htmlFor="floatingInput">Hour</label>
                        </div>

                        <div className="form-floating col-m-4 col-6">
                            <select className="form-select" value={startMinute} onChange={(e) => setStartMinute(e.target.value)}>
                                {minutes.map((minute) => (
                                    <option key={minute} value={minute}>{minute = "00" ? minute : "00"}</option>
                                ))}
                            </select>
                            <label className="control-label" htmlFor="floatingInput">Minute</label>
                        </div>
                    </div>

                    <div className="row col-md-6 col-12">
                        <label className="control-label py-2">Select End Time</label>
                        <div className="form-floating col-m-4 col-6">
                            <select className="form-select" value={endHour} onChange={(e) => setEndHour(e.target.value)}>
                                {hours.map((hour) => (
                                    <option key={hour} value={hour}>{hour}</option>
                                ))}
                            </select>
                            <label className="control-label" htmlFor="floatingInput">Hour</label>
                        </div>

                        <div className="form-floating col-m-4 col-6">
                            <select className="form-select" value={endMinute} onChange={(e) => setEndMinute(e.target.value)}>
                                {minutes.map((minute) => (
                                    <option key={minute} value={minute}>{minute = "00" ? minute : "00"}</option>
                                ))}
                            </select>
                            <label className="control-label" htmlFor="floatingInput">Minute</label>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleCreate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}
