import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import stateService from '../../../api/state_service';
import regionService from '../../../api/region';

export default function UpdateStateForm({ purpose, id,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const country_id = "659b592248e3e48cea5bbcca";
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState("");

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getRegions = async () => {

        try {
            const getData = await regionService.getRegions();
            setRegions(getData);
        }
        catch (error) {
            //console.log(error)
        }
    };

    const getState = async () => {

        try {
            const getData = await stateService.getState(id);
            if (getData) {
                setName(getData.name)
                setDescription(getData.description)
                setRegion(getData.region_id)
            }
        }
        catch (error) {
            //console.log(error)
        }
    };


    const handleUpdate = async (event) => {
        event.preventDefault();
        setDisabled(true);

        try {
            const updateData = await stateService.updateState(name, description, country_id, region, id);
            if (updateData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    useEffect(() => { getRegions() }, []);
    useEffect(() => { getState() }, []);
    const RegionsList = regions?.map(data => {
        return <option key={data["_id"]} value={data["_id"]}>{data.name}</option>
    })

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>State Updated</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Update State</h1>
                <div className="form-floating py-4 required">
                    <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Nutrient Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating col-6">
                    <select className="form-select" value={region} onChange={(e) => setRegion(e.target.value)}>
                        <option key="select">Select Region</option>
                        {RegionsList}
                    </select>
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Nutrient Description</label>
                </div>

                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}
