import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import stateService from '../../../api/state_service';
import lgaService from '../../../api/lga_service';
import ethnicityService from '../../../api/ethnicity_service';
import LGASelector from '../../FormElements/LGASelector';

export default function CreateEthnicityForm({ purpose,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [lgas, setLGA] = useState([]);
    const [selectedLGAs, setSelectedLGAs] = useState([]);

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getLGAs = async () => {
        
        try {
            const getData = await lgaService.getLGAs();
            setLGA(getData);
        }
        catch (error) {
            //console.log(error)
        }
    };


    const handleCreate = async (event) => {
        event.preventDefault();
        setDisabled(true);

        try {
            const createData = await ethnicityService.createEthnicity(name, description, selectedLGAs);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };
    
    useEffect(() => { getLGAs() }, []);

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Ethnicity Created</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Create Ethnicity</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} id="name" onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Ethnicity Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating col-6">
                    <LGASelector lgas={lgas} selectedLGAs={selectedLGAs} setSelectedLGAs={setSelectedLGAs} />
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Ethnicity Description</label>
                </div>
                
                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleCreate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}
