import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Toast } from 'react-bootstrap';
import nutrientService from '../../../api/nutrients_service'

export default function NutrientForm({ purpose, id,
    ...props
}) {
    const [macroNutrients, setMacroNutrients] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [isNotMinor, setisNotMinor] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [is_macro, setIsMacro] = useState(true);
    const [macro_id, setMacro_id] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [nutrients, setNutrients] = useState(macroNutrients);
    const [nameValidator, setNameValidator] = useState(false);
    const [macroValidator, setMacroValidator] = useState(false);

    const getNutrientsData = async () => {
        try {
            const getData = await nutrientService?.getNutrients();
            if (getData) { setMacroNutrients(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    const getNutrientData = async () => {
        try {
            const getData = await nutrientService?.getNutrient(id);
            if (getData) {
                setisNotMinor(getData?.is_macro)
                setName(getData?.name)
                setDescription(getData?.description)
                setMacro_id(getData?.macro_id)
                console.log(getData?.is_macro)
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { setShowToast(false) }, []);
    useEffect(() => { getNutrientsData() }, []);
    useEffect(() => { getNutrientData() }, []);
    useEffect(() => { setNutrients(macroNutrients?.filter(nutrient => nutrient?.is_macro === true)) }, [macroNutrients]);


    //Display list of Major Ntrients on Click that nutrient is Minor
    const handleisNotMinor = event => {
        if (isNotMinor === false) {
            setisNotMinor(true)
            setIsMacro(true)
        }
        else {
            setisNotMinor(false)
            setIsMacro(false)
        }
    };

    const validate = () => {
        name.length > 0 ? setNameValidator(false) : setNameValidator(true)
        if ((is_macro === true) || (macro_id.length > 0 && is_macro === false)) { setMacroValidator(false) } else { setMacroValidator(true) }

        if (nameValidator === true || macroValidator === true) {
            return false;
        }
        return true;
    }

    const handleUpdate = async (event) => {
        event?.preventDefault();
        if (validate() === false) {
            return
        }
        setDisabled(true);
        is_macro ?? setMacro_id("")

        try {
            const createData = await nutrientService.updateNutrient(name, description, is_macro, macro_id, id);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) {
            setDisabled(false);
            setShowToast(true);
        }
    };

    const search = event => {
        const input = event?.target?.value?.toLowerCase();
        let filteredList = macroNutrients;

        filteredList = macroNutrients?.filter(data => {
            let result = false;
            if (data.name?.toLowerCase()?.includes(input) && result === false) {
                result = true;
            }
            return result;
        });

        setNutrients(filteredList);
    };

    const list = nutrients?.map(data => (
        <div className="form-check" key={data?._id} >
            <input className="form-check-input" type="radio" id="flexRadioDefault1"
                checked={(data?._id === macro_id) && true}
                value={data?._id} onChange={(e) => setMacro_id(data?._id)} />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
                {data.name}
            </label>
        </div>
    ));

    const myNutrients = nutrients?.map(data => {
        if (data.macro_id === id) {
            return <a href={`/view-nutrient/${data._id}`} className='py-0'> {data.name} </a>
        }
    }
    );

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>{name} updated successfully</Toast.Body>
                </Toast>
            </div>

            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Nutrient</h1>

                <div className="form-floating py-4 required">
                    <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Nutrient Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Nutrient Description</label>
                </div>
                <div className="form-check">
                    <input className={macroValidator ? `form-check-input is-invalid` : "form-check-input"} type="checkbox" value=""
                        id="flexCheckDefault" onClick={handleisNotMinor} checked={!isNotMinor ? true : false} />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Is Minor nutrient{!isNotMinor && <small className='text-danger'>*</small>}
                    </label>
                    {macroValidator && (<p className='text-danger'>Once selected, you must pick associated Macro nutrient</p>)}
                </div>

                {!isNotMinor && (<div>
                    <hr />
                    <input className="form-control py-2 my-4 form-control-sm" type="search" placeholder="Search main mutrients" aria-label="Search" onChange={search} />
                    <div className='row py-4 grid row-gap-3'>
                        {list}
                    </div>
                </div>)
                }
                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                </div>
            </form>

            {myNutrients}
        </div>
    )
}