import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import chefActionsService from '../../api/chef_action';
import recipeUnitSchemeService from '../../api/recipe_unit_scheme_service';



export default function RecipceUnitScheme() {
    const [recipe_unit_scheme, setRecipceUnitScheme] = useState([]);

    if (recipe_unit_scheme.length > 0) {
        recipe_unit_scheme.map(data => data.name = data.names[0].name )
    }

    const getRecipceUnitSchemeData = async () => {
        try {
            const getData = await recipeUnitSchemeService.getRecipeUnitSchemes();
            if (getData) { setRecipceUnitScheme(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getRecipceUnitSchemeData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-recipe-unit-scheme" className="btn btn-dark">
                    Add Recipe Unit Scheme
                </NavLink>
            </div>
            {recipe_unit_scheme.length > 0 && < TableWrapper data={recipe_unit_scheme} link="/view-recipe-unit-scheme" />}
        </div>
    );
}