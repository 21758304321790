import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Footer() {

    return (
        <div className="container py-4">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <p className="col-md-4 mb-0 text-body-secondary">© 2024 Jatado, Inc</p>

                <ul className="nav col-md-4 justify-content-end">
                </ul>
            </footer>
        </div>
    )
}