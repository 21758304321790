import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import stateService from '../../../api/state_service';
import lgaService from '../../../api/lga_service';

export default function UpdateLGAForm({ purpose, id,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [states, setStates] = useState([]);
    const [state, setState] = useState("");

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getStates = async () => {
        
        try {
            const getData = await stateService.getStates();
                setStates(getData);
        }
        catch (error) {
            //console.log(error)
        }
    };

    const getLGA = async () => {
        
        try {
            const getData = await lgaService.getLGA(id);
                if(getData){
                    setName(getData.name)
                    setDescription(getData.description)
                    setState(getData.state_id)
                }
        }
        catch (error) {
            //console.log(error)
        }
    };


    const handleUpdate = async (event) => {
        event.preventDefault();
        setDisabled(true);

        try {
            const updateData = await lgaService.updateLGA(name, description, state, id);
            if (updateData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };
    
    useEffect(() => { getStates() }, []);
    useEffect(() => { getLGA() }, []);
    const StatesList = states?.map(data => {
        return <option key={data["_id"]} value={data["_id"]}>{data.name}</option>
    })

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>LGA Updated</Toast.Body>
                </Toast>
            </div>
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Local Govenment Area</h1>
                <div className="form-floating py-4 required">
                    <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">LGA Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating col-6">
                            <select className="form-select" value={state} onChange={(e) => setState(e.target.value)}>
                                <option key="select">Select State</option>
                                {StatesList}
                            </select>
                        </div>
                        <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">LGA Description</label>
                </div>
                
                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}
