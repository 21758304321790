import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import lgaService from '../../api/lga_service';


export default function LGA() {
    const [lga, setLGAs] = useState([]);

    const getlgaData = async () => {
        try {
            const getData = await lgaService.getLGAs();
            if (getData) { setLGAs(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getlgaData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-lga" className="btn btn-dark">
                    Add LGA
                </NavLink>
            </div>
            <h2 className='text-center py-4'>Local Government Areas</h2>
            {lga.length > 0 && < TableWrapper data={lga} link="/view-lga" />}
        </div>
    );
}