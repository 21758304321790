import React, { useState, useEffect } from 'react';
import nutrientService from '../../../api/nutrients_service';
import foodItemsService from '../../../api/food_items_service';
import recipeUnitSchemeService from '../../../api/recipe_unit_scheme_service';
import Tabs from '../../tab/tab';
import Pills from '../../pills/pills';
import { sortItemsByName } from '../../../helper/utils';
import Modal from '../../modal/modal';
import ImageUpload from '../../FormElements/ImageUpload';
import microServices from '../../../api/micro_services';

export default function UpdateItemForm({ purpose, id,
    ...props
}) {
    const [showModal, setShowModal] = useState(false);
    const [allNutrients, setAllNutrients] = useState();
    let nutrientList;
    const [nutrient, nutrientState] = useState(nutrientList);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [name, setName] = useState("");
    const [language, setLanguage] = useState("659b6f8f48e3e48cea5bbccf");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [itemNutrients, setItemNutrients] = useState([]);
    const [recipeScheme, setRecipeScheme] = useState([]);
    const [nutrients, setAllNutrientsQuantity] = useState([]);
    const [perUnitQuantity, setPerUnitQuantity] = useState("");
    const [measuringUnit, setMeasuringUnit] = useState("");
    const [food_items, setFoodItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [imageFile, setImageFile] = useState(null); // Add state to hold the image file
    const [previousImageUrl, setPreviousImageUrl] = useState(null);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const getFoodItemsData = async () => {
        try {
            const getData = await foodItemsService.getFoodItems();

            if (getData) {
                getData.map(data => data.name = data.names[0].name)
                const sortedData = sortItemsByName(getData);
                setFoodItems(sortedData);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getFoodItem = async () => {
        try {
            const getData = await foodItemsService.getFoodItem(id);
            if (getData) {
                setName(getData?.names[0]?.name);
                getData.names[0]?.language_id && setLanguage(getData?.names[0]?.language_id);
                setDescription(getData?.description);
                setItemNutrients(getData?.nutrients);
                setAllNutrientsQuantity(getData?.nutrients);
                setPerUnitQuantity(getData?.per_unit_quantity);
                setMeasuringUnit(getData?.measuring_unit);
                setSelectedItems(getData?.substitutes);
                setImageUrl(getData.img);
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    const getNutrientsData = async () => {
        try {
            const getData = await nutrientService.getNutrients();
            if (getData) { setAllNutrients(getData) }
        }
        catch (error) {
            console.log(error)
        }
        //updateNutrients()
    };

    const updateNutrients = () => {
        const updatedNutrients = [...allNutrients, itemNutrients[0]]
        setAllNutrients(updatedNutrients)
    }

    const getRecipeSchemeData = async () => {
        try {
            const getData = await recipeUnitSchemeService.getRecipeUnitSchemes();
            if (getData) { setRecipeScheme(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    const handleNutrientsChange = (id, event) => {
        const singleNutrient = { nutrient_id: id, quantity: event.target.value, unit: "g" }
        let newInputValues = nutrients.filter(data => data.nutrient_id !== id)
        if (event.target.value !== "") {
            newInputValues = [...newInputValues, singleNutrient];
        }
        setAllNutrientsQuantity(newInputValues);
    }

    /*Handles FIle change in Image Field */
    const handleFileChange = (file) => {
        setImageFile(file);
        if(imageUrl){
            setPreviousImageUrl(imageUrl);
        }
      };

    const handleUpdate = async (event) => {
        event.preventDefault();
        const unit = "g"
        const names = { 'language_id': language, 'name': name }
        var urlHolder = imageUrl;
        const substitutes = selectedItems;/* 
        console.log("Unit" + measuringUnit);
        console.log("Quantiyt" + perUnitQuantity); */

        setDisabled(true);

        if (imageFile) {
            // Upload image using microServices
            const folder = 'Dishes'; // Or any folder name
            //setImageUrl(await microServices.imageUploadAPI(folder, imageFile));
            urlHolder = await microServices.imageUploadAPI(folder, imageFile, previousImageUrl);
            setImageUrl(urlHolder);
            
        }

        try {
            const updateData = await foodItemsService.updateFoodItem(names, description, measuringUnit, perUnitQuantity, nutrients, unit, id, substitutes,  urlHolder);
            if (updateData) {
                handleShowModal();
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    useEffect(() => { getFoodItem() }, []);
    useEffect(() => { getNutrientsData() }, []);
    useEffect(() => { getRecipeSchemeData() }, []);
    useEffect(() => { nutrientState(allNutrients) }, [allNutrients]);
    useEffect(() => { getFoodItemsData() }, []);
    //setAllNutrientsQuantity(itemNutrients);
    //useEffect(() => { updateNutrients() }, []);
    nutrientList = allNutrients

    const search = event => {
        const input = event?.target?.value?.toLowerCase();
        let filteredList = nutrientList;

        filteredList = nutrientList?.filter(data => {
            let result = false;
            if (data?.toLowerCase()?.includes(input) && result === false) {
                result = true;
            }
            return result;
        });

        nutrientState(filteredList);
    };

    const list = nutrient?.map(data => {
        let value = ""

        itemNutrients?.map(itemNutrient => {
            if (itemNutrient.nutrient_id === data._id) {
                value = itemNutrient.quantity
                return
            }
        })

        return (
            <div className={value !== "" ? 'col - lg - 3 col-md-4 col-6 border border-primary border-2' : "col - lg - 3 col-md-4 col-6"}>
                <label htmlFor="floatingTextarea">{data["name"]} (g)</label>
                <textarea className="form-control" label={data["name"]} key={data["_id"]} onChange={(event) => handleNutrientsChange(data["_id"], event)}>
                    {value}
                </textarea>
            </div>
        )
    })

    const listOfMeasuringUnits = recipeScheme?.map(data => {
        return <option key={data["_id"]} value={data["_id"]} selected={measuringUnit == data["_id"] && "true"}>{data.names[0].name}</option>
    })


    const tabs = [
        {
            id: 'nav-home',
            label: 'Item',
            content: (
                <form className='bg-light p-3'>
                    <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Food Item</h1>
                    <div className="form-floating required py-4">
                        <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} id="name" value={name} onChange={(e) => setName(e.target.value)} />
                        <label className="control-label" htmlFor="floatingInput">Item Name<small className='text-danger'>*</small></label>
                        {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                    </div>
                    <div className="form-floating py-4">
                        <textarea type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        <label className='control-label' htmlFor="floatingInput">Item Description</label>
                    </div>
                    <div className='row'>
                        <div className="form-floating py-4 col-8">
                            <select className="form-select" aria-label="Default select example" onChange={(e) => setMeasuringUnit(e.target.value)}>
                                <option selected>Unit of Measurement</option>
                                {listOfMeasuringUnits}
                            </select>
                        </div>

                        <div className="form-floating py-4 col-4">
                            <textarea type="text" className="form-control" id="description" value={perUnitQuantity} onChange={(e) => setPerUnitQuantity(e.target.value)} />
                            <label className='control-label' htmlFor="floatingInput">Amount</label>
                        </div>
                        <div className="container bg-white p-5 my-3">
                            <ImageUpload imageUrl={imageUrl} onFileChange={handleFileChange} />
                        </div>
                    </div>
                    <hr />
                    <input className="form-control py-2 my-4 form-control-sm" type="search" placeholder="Search Items" aria-label="Search" onChange={search} />
                    <div className='row py-4 grid row-gap-3'>
                        {list}
                    </div>
                    <div className='row justify-content-center'>
                        <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                    </div>
                </form>),
        },
        {
            id: 'nav-substitute',
            label: 'Substitutes',
            content: (
                <form className="bg-light p-3">
                    <h2 className="my-4 py-4 text-center">{name} substitute</h2>
                    <Pills items={food_items} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
                    <div className='row justify-content-center'>
                        <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                    </div>
                </form>
            )
        }
    ]

    return (
        <div>
            <Modal show={showModal} handleClose={handleCloseModal} title="Item Updated Successfully">
                Item added successfully.
            </Modal>
            <Tabs tabs={tabs} />
        </div>
    );
}