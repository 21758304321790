import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import mealTypeService from '../../api/meal_type';


export default function MealType() {
    const [meal_types, setMealType] = useState([]);

    const getMealTypeData = async () => {
        try {
            const getData = await mealTypeService.getMealTypes();
            if (getData) {
                getData?.map(data => data.name = data.names[0].name)
                setMealType(getData)
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getMealTypeData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-meal-type" className="btn btn-dark">
                    Add Meal Type
                </NavLink>
            </div>
            {meal_types.length > 0 && < TableWrapper data={meal_types} link="/view-meal-type" />}
        </div>
    );
}