import React from 'react';
import CreateStateForm from '../../components/Form/state/create';


export default function CreateState() {
    return (
        <div>
            {/*<CreateDishForm purpose="Create" />*/}
            <CreateStateForm purpose="Create" />
        </div>
    )
}