import axios from 'axios';
import { BaseURL } from './service_variables';

const ethnicityMealScoreService = {
  createEthnicityMealScore: async (description, score, ethnicity_id, meal_id) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { description, score, ethnicity_id, meal_id };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/ethnicities_meal_score/`;

    try {
      const response = await axios.post(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },

  getEthnicityMealScores: async () => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/ethnicities_meal_score/`;

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      //console.log(response.data.data)
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  getEthnicityMealScore: async (id) => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/ethnicities_meal_score/${id}`;

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  updateEthnicityMealScore: async (id, description, score, ethnicity_id, meal_id) => {

    const TOKEN = window.localStorage.getItem('token');
    const data = { id, description, score, ethnicity_id, meal_id};

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/ethnicities_meal_score/${id}`;

    try {
      const response = await axios.put(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },
};

export default ethnicityMealScoreService;
