import axios from 'axios';
import { BaseURL } from './service_variables';

const nutrientService = {
  createNutrient: async (name, description, is_macro, macro_id) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { name, description, is_macro, macro_id };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/nutrients/`;

    try {
      const response = await axios.post(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },

  getNutrients: async () => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/nutrients/`;

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      //console.log(response.data.data)
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  getNutrient: async (id) => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/nutrients/${id}`;

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  updateNutrient: async (name, description, is_macro, macro_id, id) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { name, description, is_macro, macro_id };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/nutrients/${id}`;

    try {
      const response = await axios.put(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },
};

export default nutrientService;
