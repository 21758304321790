import { useState } from "react";

const LGASelector = ({ lgas, selectedLGAs, setSelectedLGAs }) => {
    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    // Filter LGAs based on search input
    const filteredLGAs = lgas.filter(lga =>
        lga.name.toLowerCase().includes(search.toLowerCase())
    );

    // Toggle selection
    const handleToggle = (id) => {
        setSelectedLGAs((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter(lgaId => lgaId !== id)
                : [...prevSelected, id]
        );
    };

    return (
        <div className="col-6 position-relative">
            {/* Selected Items Display */}
            <div 
                className="form-control d-flex justify-content-between align-items-center"
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: "pointer" }}
            >
                <span>
                    {selectedLGAs.length > 0
                        ? lgas
                            .filter(lga => selectedLGAs.includes(lga["_id"]))
                            .map(lga => lga.name)
                            .join(", ")
                        : "Select LGAs"}
                </span>
                <span>&#9660;</span> {/* Dropdown arrow */}
            </div>

            {/* Dropdown Menu */}
            {isOpen && (
                <div className="dropdown-menu show w-100 p-2" style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    position: "absolute",
                    zIndex: 1000,
                    width: "100%"
                }}>
                    {/* Search Input */}
                    <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    {/* Checkbox List */}
                    {filteredLGAs.map((lga) => (
                        <div key={lga["_id"]} className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`lga-${lga["_id"]}`}
                                checked={selectedLGAs.includes(lga["_id"])}
                                onChange={() => handleToggle(lga["_id"])}
                            />
                            <label className="form-check-label" htmlFor={`lga-${lga["_id"]}`}>
                                {lga.name}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LGASelector;
