import axios from 'axios';
import { BaseURL } from './service_variables';

const foodItemsService = {
  createFoodItem: async (names, description, measuringUnit, perUnitQuantity, nutrients, img) => {
    names = [names]
    const measuring_unit = measuringUnit;
    const per_unit_quantity = perUnitQuantity;

    const TOKEN = window.localStorage.getItem('token');
    const data = { names, description, measuring_unit, per_unit_quantity, nutrients, img };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/food_items/`;

    try {
      const response = await axios.post(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },

  getFoodItems: async () => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/food_items/`;

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      //console.log(response.data.data)
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  getFoodItem: async (id) => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/food_items/${id}`;

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      console.log(response)
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  updateFoodItem: async (names, description, measuringUnit, perUnitQuantity, nutrients, unit, id, substitutes, img) => {
    names = [names]
    const measuring_unit = measuringUnit;
    const per_unit_quantity = perUnitQuantity;

    const TOKEN = window.localStorage.getItem('token');
    const data = { names, description, measuring_unit, per_unit_quantity,  nutrients, unit, id, substitutes, img};

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/food_items/${id}`;

    try {
      const response = await axios.put(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },
};

export default foodItemsService;
