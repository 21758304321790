import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import chefActionsService from '../../api/chef_action';



export default function ChefActions() {
    const [food_items, setFoodItems] = useState([]);

    if (food_items.length > 0) {
        food_items.map(data => data.name = data.names[0].name )
    }

    const getFoodItemsData = async () => {
        try {
            const getData = await chefActionsService.getChefActions();
            if (getData) { setFoodItems(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getFoodItemsData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-chef-action" className="btn btn-dark">
                    Add Chef Action
                </NavLink>
            </div>
            {food_items.length > 0 && < TableWrapper data={food_items} link="/view-chef-action" />}
        </div>
    );
}