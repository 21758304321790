import axios from 'axios';
import { BaseURL } from './service_variables';

const stateService = {
  createState: async (name, description, country_id, region_id) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { name, description, country_id, region_id };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/states/`;
    console.log("Data", data)

    try {
      const response = await axios.post(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },

  getStates: async () => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/states/`;
    //const url = 'https://diet-tribe-admin-914a22e61065.herokuapp.com/states/'

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      console.log(response.data)
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  getState: async (id) => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/states/${id}`;
    //const url = `https://diet-tribe-admin-914a22e61065.herokuapp.com/states/${id}`

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  updateState: async (name, description, country_id, region_id, id) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { name, description, country_id, region_id, id};

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/states/${id}`;
    //const url = `https://diet-tribe-admin-914a22e61065.herokuapp.com/states/${id}`

    try {
      const response = await axios.put(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },
};

export default stateService;