import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import DishService from '../../../api/dish_service';
import recipeUnitSchemeService from '../../../api/recipe_unit_scheme_service';
import foodItemsService from '../../../api/food_items_service';
import DynamicFields from '../../FormElements/DynamicFields';
import DynamicPills from '../../DynamicPills/DynamicPills';
import ImageUpload from '../../FormElements/ImageUpload';
import microServices from '../../../api/micro_services';

export default function UpdateDishForm({ purpose, id,
    ...props
}) {
    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const language = "659b6f8f48e3e48cea5bbccf";
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [servings, setServings] = useState("");
    const [description, setDescription] = useState("");
    const [instructions, setInstructions] = useState([]);
    const [ingredients, setAllIngredients] = useState([]);
    const [ingredientContainer, setIngredientsContainer] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const sets = ingredients;
    const [imageUrl, setImageUrl] = useState(null);
    const [imageFile, setImageFile] = useState(null); // Add state to hold the image file
    const [previousImageUrl, setPreviousImageUrl] = useState(null);
    //const [selectedIngredients, setSelectedIngredients] = useState([]);
    //const sets = ingredients;

    useEffect(() => {
        setShowToast(false);
    }, []);

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getDish = async () => {
        try {
            const getData = await DishService.getDish(id);
            if (getData) {
                //console.log(getData)
                setName(getData.names[0].name);
                //setLanguage(getData.names[0].language_id);
                setDescription(getData.description);
                setInstructions(getData.instructions);
                setServings(getData.servings);
                setSelectedOptions(getData.ingredients);
                setImageUrl(getData.img);

                /* //Fill selected options
                const selectedOptionHolder = getData.ingredients.map(data => {
                    if (data.names && data.names.length > 0) {
                        // Safely set the first name if the names array exists and has elements
                        data.name = data.names[0].name;
                    } else {
                        // Provide a fallback value if names is undefined or empty
                        data.name = 'Unnamed Ingredient'; // Or some default value
                    }
                    return data;
                })
                /* console.log('options')
                console.log(selectedOptionHolder) *
                setSelectedOptions(selectedOptionHolder.ingredients); */
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    const getFoodItemsData = async () => {
        try {
            const getData = await foodItemsService.getFoodItems();
            const getUnitofMeasurement = await recipeUnitSchemeService.getRecipeUnitSchemes();

            if (getData.length > 0) {
                const getIngredients = getData?.map(data => {
                    data.name = data.names[0].name;
                    if (data.measuring_unit) {
                        getUnitofMeasurement.forEach(obj => {
                            if (obj._id === data.measuring_unit) {
                                //data.measuring_unit = obj.names[0].name;
                                const item = { ...data, measuring_unit: obj.names[0].name };
                                ////console.log(item);
                            }
                        })
                    }
                    return data;
                })
                setAllIngredients(getIngredients);

                //const item = unitMeasurements.find(obj => obj.id === data._id);
            }
            if (getData) { setIngredientsContainer(getData) }
            //if (getUnitofMeasurement) { setUnitMeasurements(getUnitofMeasurement) }
        }
        catch (error) {
            //console.log(error)
        }
    };

    /* Mutate the selectedOption variable to add corresponding name of ingredients */
    const refineSelectedOptions = () => {
        const refinedOptions = selectedOptions?.map(Optiondata => {
            ingredients?.map(ingredientData => {
                if (Optiondata.item_id == ingredientData._id) {
                    Optiondata.name = ingredientData.names[0].name;
                }
            })
        })
    }

    /*Handles FIle change in Image Field */
    const handleFileChange = (file) => {
        setImageFile(file);
        if(imageUrl){
            setPreviousImageUrl(imageUrl);
        }
      };


    const handleUpdate = async (event) => {
        event.preventDefault();
        const names = { 'language_id': language, 'name': name }
        var urlHolder = imageUrl;
        setDisabled(true);

        try {

            if (imageFile) {
                // Upload image using microServices
                const folder = 'Dishes'; // Or any folder name
                //setImageUrl(await microServices.imageUploadAPI(folder, imageFile));
                urlHolder = await microServices.imageUploadAPI(folder, imageFile, previousImageUrl);
                setImageUrl(urlHolder);
                
              }

            const updateData = await DishService.updateDish(names, description, instructions, servings, selectedOptions, urlHolder, id);
            //console.log(createData)
            if (updateData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    useEffect(() => { getDish() }, []);
    useEffect(() => { getFoodItemsData() }, []);
    useEffect(() => { refineSelectedOptions() }, [ingredients]);

    const handleIngredientChange = (id, index, event) => {
        const newInputValues = selectedOptions?.map(data =>
            data.item_id === id ? { ...data, [index]: event.target.value } : data)
        setSelectedOptions(newInputValues);
    }

    const handleIngredientChecked = (id, index, event) => {
        const newInputValues = selectedOptions?.map(data =>
            data.item_id === id ? { ...data, [index]: event.target.checked } : data)
        setSelectedOptions(newInputValues);
    }

    const handleRecipeChange = (values) => {
        setInstructions(values);
    };

    /*************Handling and creating Table fields of selected ingredients */
    const handleSelectIngredient = (event) => {
        var ingredientName = event.target.name;
        var measuringUnit = event.target.measuring_unit;
        var id = event.target.id;
        var itsPresent = false;
        var ingredient = { item_id: id, quantity: 0, name: ingredientName, measuring_unit: measuringUnit, main_ingredient: false, reduceable: false, removeable: false, substitutes: [] }
        ////console.log(ingredient)
        let selectedLabels = [...selectedOptions];
        if (selectedLabels.length > 0) {
            itsPresent = selectedLabels?.some(item => item.name.includes(ingredient?.name))
        }
        if (itsPresent) {
            selectedLabels = selectedLabels.filter(item => item.name !== ingredient.name);
            setSelectedOptions(selectedLabels);
        }
        else {
            selectedLabels = [...selectedOptions, ingredient]
            setSelectedOptions(selectedLabels);
        }
    };

    /*******Search algorithm */
    const search = event => {
        event.preventDefault();
        const input = event?.target?.value?.toLowerCase();
        let filteredList = sets;

        filteredList = sets?.filter(item => {
            let result = false;
            if (item?.name?.toLowerCase()?.includes(input) && result === false) {
                result = true;
            }
            return result;
        });

        setIngredientsContainer(filteredList);
    };

    const list = ingredientContainer?.map(set => {
        return (
            <div className='mb-3 col-6' key={set._id}>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id={set._id} name={set.name} measuring_unit={set.measuring_unit} onClick={handleSelectIngredient} checked={selectedOptions?.some(option => option.item_id === set._id)}  // Check if ingredient is in selectedOptions
                        onChange={() => { }}  // Empty onChange to avoid React warning about controlled input without onChange
                    />
                    <label className="form-check-label w-100" htmlFor={set.name} >
                        {set.name}
                    </label>
                </div>
            </div>)
    })

    const ingredientList = selectedOptions?.map(set => {
        return (
            <tr key={set.item_id} className="g-3">
                <th className='col-2' scope="row">{set.name}</th>
                <td className='col-5'> <div className="form-floating">
                    <input type="text" className="form-control" id={set.item_id} value={set.quantity}  onChange={(e) => handleIngredientChange(set.item_id, 'quantity', e)} />
                    <label htmlFor={set.item_id}>{set.measuring_unit}</label>
                </div></td>
                <td className='col-1'><input className="form-check-input" type="checkbox" checked={set.main_ingredient || ''} id="flexCheckDefault" onChange={(e) => handleIngredientChecked(set.item_id, 'main_ingredient', e)} /></td>
                <td className='col-1'><input className="form-check-input" type="checkbox" checked={set.reduceable || ''} id="flexCheckDefault" onChange={(e) => handleIngredientChecked(set.item_id, 'reduceable', e)} /></td>
                <td className='col-1'><input className="form-check-input" type="checkbox" checked={set.removeable || ''} id="flexCheckDefault" onChange={(e) => handleIngredientChecked(set.item_id, 'removeable', e)} /></td>
            </tr>
        );
    });


    //useEffect(() => { getFoodItemsData() }, []);
    //console.log(selectedIngredients);

    return (
        <div>
            {/* <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Dish Updated</Toast.Body>
                </Toast>
            </div>
 */}
            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Dish</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={nameValidator ? `form-control is-invalid` : "form-control"} id="name" value={name} onChange={(e) => setName(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Dish Name<small className='text-danger'>*</small></label>
                    {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Dish Description</label>
                </div>
                <div className="container bg-white p-5 my-3">
                    <ImageUpload imageUrl={imageUrl} onFileChange={handleFileChange} />
                    {/* {imageUrl && <img src={imageUrl} alt="Preview" width="100" />} */}
                </div>

                <div className="border bg-white p-3">
                    <h5 className='p-2 text-center'>Ingredients and Measurement</h5>
                    <div className="dropdown d-flex justify-content-end mr-4">
                        <button type="button" className="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                            Select Ingredients
                        </button>
                        <div className="dropdown-menu p-4 w-50">
                            <form className="w-100 fluid" role="search">
                                <input className="form-control" type="search" placeholder="Search" aria-label="Search" onChange={search} />
                            </form>
                            <div className="row">
                                {list}
                            </div>
                        </div>
                    </div>

                    <div className="m-4 g-3  p-4">
                        <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Measurement</th>
                                    <th scope="col">Main</th>
                                    <th scope="col">Reduce</th>
                                    <th scope="col">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ingredientList}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="form-floating py-4">
                    <DynamicFields onFieldsChange={handleRecipeChange} initialData={instructions} />
                    <label className='control-label' htmlFor="floatingInput">Instructions</label>
                </div>

                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" id="servings" value={servings}  onChange={(e) => setServings(e.target.value)} />
                    <label className='control-label pb-2' htmlFor="floatingInput">Servings</label>
                </div>

                <div className="container mt-5">
                <h1 className='text-center'>Manage ingredients substitutes</h1>
                <DynamicPills items={selectedOptions} possibleSubstitutes={ingredients} selectedIngredients={selectedOptions} setSelectedIngredients={setSelectedOptions} />
            </div>


                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Dish Updated</Toast.Body>
                </Toast>
            </div>
        </div>
    )
}
