import React, { useState, useEffect } from 'react';
import factorService from '../../../api/factors_service';
import CreateEthnicityMealScoreForm from '../ethnicity_meal_scores/create';
import UpdateEthnicityMealScoreForm from '../ethnicity_meal_scores/update';

export default function EthnicityMealTable({ list, ethnicity_id, ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const language = "659b6f8f48e3e48cea5bbccf";
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");

    useEffect(() => {
        setShowToast(false);
    }, []);

    var tableList;

    if (list) {
        tableList = list?.map(data => {
            return (
                <tr key={data?.id}>
                    <td className="col-6">{data?.name}</td>
                    <td className="col-4">{data?.score}</td>
                    <td className="col-1"><button type="button" className="btn btn-dark my-2" data-bs-toggle="modal" data-bs-target={`#staticBackdrop${data.id}`}>
                        Score  {name}
                    </button>

                        <div className="modal fade" id={`staticBackdrop${data.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`staticBackdrop${data.id}Label`} aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id={`staticBackdrop${data.id}Label`}>Score</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        {!data?.score ? <CreateEthnicityMealScoreForm purpose="Create" ethnicity_id={ethnicity_id} name={data?.name} meal_id={data?.id} /> :
                                            <UpdateEthnicityMealScoreForm purpose="Update" id={data?.score_id} ethnicity_id={ethnicity_id} name={data?.name} meal_id={data?.id} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div>
            <table className="table table-striped">
                <thead>
                    <tr className="p-2">
                        <th className="col-7">Item</th>
                        <th className="col-3">Score</th>
                        <th className="col-2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableList}
                </tbody>
            </table>
        </div>
    )
}
