import axios from 'axios';
import { BaseURL } from './service_variables';

const ethnicityService = {
  createEthnicity: async (name, description, lga) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { name, description, lga };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/ethnicities/`;
    console.log("Data", data)

    try {
      const response = await axios.post(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },

  getEthnicities: async () => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/ethnicities/`;
    //const url = 'https://diet-tribe-admin-914a22e61065.herokuapp.com/ethnicities/'

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      //console.log(response.data)
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  getEthnicity: async (id) => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/ethnicities/${id}`;
    //const url = `https://diet-tribe-admin-914a22e61065.herokuapp.com/ethnicities/${id}`

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  updateEthnicity: async (name, description, state_id, id) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { name, description, state_id, id};

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/ethnicities/${id}`;
    //const url = `https://diet-tribe-admin-914a22e61065.herokuapp.com/ethnicities/${id}`

    console.log(data);

    try {
      const response = await axios.put(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },
};

export default ethnicityService;