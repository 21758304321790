import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import factorCategoryService from '../../api/factors_category_service';
import stateService from '../../api/state_service';


export default function States() {
    const [countryStates, setCountryStates] = useState([]);

    const getStatesData = async () => {
        try {
            const getData = await stateService.getStates();
            if (getData) { setCountryStates(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getStatesData() }, []);

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-state" className="btn btn-dark">
                    Add State
                </NavLink>
            </div>
            <h2 className='text-center py-4'>States</h2>
            {countryStates.length > 0 && < TableWrapper data={countryStates} link="/view-state" />}
        </div>
    );
}