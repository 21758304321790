import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import lgaService from '../../../api/lga_service';
import ethnicityService from '../../../api/ethnicity_service';
import LGASelector from '../../FormElements/LGASelector';
import mealService from '../../../api/meal_service';
import ethnicityMealScoreService from '../../../api/ethnicity_meal_score_service';
import EthnicityMealTable from './table';
import TableWrapper from '../../table/table_wrapper';
import DishService from '../../../api/dish_service';

export default function UpdateEthnicityForm({ purpose, id,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [name, setName] = useState("");
    const [nameValidator, setNameValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [lgas, setLGA] = useState([]);
    const [selectedLGAs, setSelectedLGAs] = useState([]);
    const [ethnicitys, setScoreData] = useState([]);
    const [meals, setMeals] = useState([]);
    const [mealsSet, setMealsSet] = useState([]);
    const [mealsUnset, setMealsUnset] = useState([]);

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getLGAs = async () => {

        try {
            const getData = await lgaService.getLGAs();
            setLGA(getData);
        }
        catch (error) {
            //console.log(error)
        }
    };

    const getEthnicity = async () => {

        try {
            const getData = await ethnicityService.getEthnicity(id);
            if (getData) {
                setName(getData.name)
                setDescription(getData.description)
                setSelectedLGAs(getData.lga)
            }
        }
        catch (error) {
            //console.log(error)
        }
    };

    const getMealsData = async () => {
        try {
            const getData = await mealService.getMeals();
            const getDishes = await DishService.getDishes();

            if (getData && getDishes) {
                // Adding the name field to each item in getData
                getData.forEach(item => {
                    let tempName = "";
                    item.courses[0].dishes.forEach(data => {
                        const dish = getDishes.find(dish => dish._id === data.dish_id);
                        if (dish) {
                            tempName += dish.names[0].name + ", ";
                        }
                    });
            
                    // Remove trailing comma and space
                    item.name = tempName.trim().replace(/,$/, "");
                });
            }

            if (getData) {
                getData.sort(function(a, b) {
                    // Convert names to lowercase for case-insensitive sorting
                    let nameA = a.name.toLowerCase();
                    let nameB = b.name.toLowerCase();
                    
                    if (nameA < nameB) {
                        return -1; // nameA comes before nameB
                    }
                    if (nameA > nameB) {
                        return 1; // nameB comes before nameA
                    }
                    return 0; // names are equal
                });
                setMeals(getData);
            }

    const getScoreData = await ethnicityMealScoreService.getEthnicityMealScores();
    const scoreData = getScoreData?.filter(data => { if (id === data.ethnicity_id) { return true } else { return false } })

        console.log("ScoreData", scoreData)

    if (getData) {
        const unsetMealHolder = getData.filter(data => {
            if (scoreData && scoreData.some(score => (score.meal_id == data._id))) {
                return false;
            } else {
                return true;
            }
        });

        var cunsetMealHolder = [];
        unsetMealHolder?.map(data => {
            var container = { "score": "", "name": data.name, "id": data._id, "score_id": "" }
            cunsetMealHolder.push(container)
        })

        //console.log("get Oh", getData)
        var setMealHolder = [];
        scoreData?.map(score => {
            //console.log("Score", score)
            getData?.map(
                meal => {
                    if (meal._id == score.meal_id) {
                        var container = { "score": score.score, "name": meal.name, "id": meal._id, "score_id": score._id }
                        setMealHolder.push(container)
                        return
                    }
                }
            )
        })

        console.log("setMealHolder", setMealHolder)
        //console.log("unsetMealHolder", unsetMealHolder)

        setMealsUnset(cunsetMealHolder);
        setMealsSet(setMealHolder);
    }
}
        catch (error) {
    console.log(error)
}
    };


const handleUpdate = async (event) => {
    event.preventDefault();
    setDisabled(true);

    try {
        const createData = await ethnicityService.updateEthnicity(name, description, selectedLGAs, id);
        if (createData) {
            setShowToast(true);
            setDisabled(false);
        }
    }
    catch (error) { setDisabled(false); }
};

useEffect(() => { getMealsData() }, []);
useEffect(() => { getLGAs() }, []);
useEffect(() => { getEthnicity() }, []);

return (
    <div>
        <div className='d-flex justify-content-end'>
            <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                <Toast.Header className='d-flex justify-content-end'>
                </Toast.Header>
                <Toast.Body>Ethnicity Updated</Toast.Body>
            </Toast>
        </div>

        <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Factor</button>
                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Score {name} Meals</button>
                <button className="nav-link" id="nav-filled-tab" data-bs-toggle="tab" data-bs-target="#nav-filled" type="button" role="tab" aria-controls="nav-filled" aria-selected="false">Already Scored {name} Meals</button>
            </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">
                <form className='bg-light p-3'>
                    <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Ethnicity</h1>
                    <div className="form-floating py-4 required">
                        <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                        <label className="control-label" htmlFor="floatingInput">Nutrient Name<small className='text-danger'>*</small></label>
                        {nameValidator && (<p className='text-danger'>Name is required.</p>)}
                    </div>
                    <div className="form-floating col-6">
                        <LGASelector lgas={lgas} selectedLGAs={selectedLGAs} setSelectedLGAs={setSelectedLGAs} />
                    </div>
                    <div className="form-floating py-4">
                        <textarea type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        <label className='control-label' htmlFor="floatingInput">Nutrient Description</label>
                    </div>

                    <div className='row justify-content-center'>
                        <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                    </div>
                </form>
            </div>
            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex="0">
                <h2 className='text-center py-4'>{name}</h2>
                <EthnicityMealTable list={mealsUnset} ethnicity_id={id} />
                {ethnicitys.length > 0 && < TableWrapper data={ethnicitys} link="/view-ethnicity" />}
            </div>

            <div className="tab-pane fade" id="nav-filled" role="tabpanel" aria-labelledby="nav-filled-tab" tabIndex="0">
                <h2 className='text-center py-4'>{name}</h2>
                <EthnicityMealTable list={mealsSet} ethnicity_id={id} />
                    {ethnicitys.length > 0 && < TableWrapper data={ethnicitys} link="/view-ethnicity" />}
            </div>
        </div>
    </div>
)
}
