import React from 'react';
import CreateEthnicityForm from '../../components/Form/ethnicities/create';


export default function CreateEthnicities() {
    return (
        <div>
            {/*<CreateDishForm purpose="Create" />*/}
            <CreateEthnicityForm purpose="Create" />
        </div>
    )
}