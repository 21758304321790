import { useState, useEffect } from "react";

const ImageUpload = ({ imageUrl, onFileChange }) => {
  const [preview, setPreview] = useState(imageUrl || "");

  useEffect(() => {
    setPreview(imageUrl); // Update preview when imageUrl changes
  }, [imageUrl]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file)); // Show a preview of selected image
      onFileChange(file);
    }
  };

  return (
    <div className="row align-items-center">
      <div className="col-4">
        <label className="control-label py-2" htmlFor="floatingInput">Image</label>
        
        {preview ? (
          <div>
            <img src={preview} alt="Uploaded preview" className="img-thumbnail my-2" style={{ maxWidth: "100%" }} />
            <input className="form-control mt-2" type="file" accept="image/*" onChange={handleFileChange} />
          </div>
        ) : (
          <input className="form-control" type="file" accept="image/*" onChange={handleFileChange} />
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
