import React from 'react';
import ItemForm from '../../components/Form/item/create';


export default function CreateItem() {
    return (
        <div>
            <ItemForm purpose="Create"/>
        </div>
    )
}