import axios from 'axios';
import { BaseURL } from './service_variables';

const regionService = {
  createRegion: async (name, description, country_id) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { name, description, country_id };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/region/`;

    try {
      const response = await axios.post(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },

  getRegions: async () => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/region/`;
    //const url = 'https://diet-tribe-admin-914a22e61065.herokuapp.com/region/'

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      console.log(response.data.data)
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  getRegion: async (id) => {
    const TOKEN = window.localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/region/${id}`;
    //const url = `https://diet-tribe-admin-914a22e61065.herokuapp.com/region/${id}`

    try {
      const response = await axios.get(url, { withCredentials: false, headers });
      return response.data.data; // You may return additional data if needed
    } catch (error) {
      throw error; // Handle errors as needed
    }
  },

  updateRegion: async (name, description, country_id, id) => {
    const TOKEN = window.localStorage.getItem('token');
    const data = { name, description, country_id, id};

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN}`
    };

    const url = `${BaseURL}/region/${id}`;
    //const url = `https://diet-tribe-admin-914a22e61065.herokuapp.com/region/${id}`

    try {
      const response = await axios.put(url, data, { withCredentials: false, headers });
      console.log(response)

      return response; // You may return additional data if needed
    } catch (error) {
      console.log("Failed Here")
      console.log(error)
      throw error; // Handle errors as needed
    }
  },
};

export default regionService;