import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import ethnicityMealScoreService from '../../../api/ethnicity_meal_score_service';

export default function UpdateEthnicityMealScoreForm({ purpose, id, ethnicity_id, name, meal_id,
    ...props
}) {

    const [showToast, setShowToast] = useState(false);
    const [disabled, setDisabled] = useState(false);
    /*Form Values */
    const [language, setLanguage] = useState("");
    const [score, setScore] = useState("");
    const [scoreValidator, setScoreValidator] = useState(false);
    const [description, setDescription] = useState("");
    const [ethnicitys, setEthnicityMeals] = useState([]);

    useEffect(() => {
        setShowToast(false);
    }, []);

    const getEthnicityMealCategory = async () => {
        try {
            const getData = await ethnicityMealScoreService.getEthnicityMealScore(id);
            if (getData) {
                setScore(getData.score);
                setDescription(getData.description);

            }
        }
        catch (error) {
            console.log(error)
        }
    };

    if (ethnicitys.length > 0) {
        ethnicitys.map(data => data.name = data.name)
    }

    const getethnicitysData = async () => {
        try {
            const getData = await ethnicityMealScoreService.getEthnicityMeals();
            const ethnicityData = getData?.filter(data => { if (id === data.category_id) { return true } else { return false } })
            if (getData) { setEthnicityMeals(ethnicityData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getethnicitysData() }, []);


    const handleUpdate = async (event) => {
        event.preventDefault();
        //const names = { 'language_id': language, 'name': name }

        /* if (validate() === true) {
            console.log("Failed" + nameValidator + macroValidator)
            return
        }*/
        setDisabled(true);

        try {
            const createData = await ethnicityMealScoreService.updateEthnicityMealScore(id, description, score, ethnicity_id, meal_id);
            if (createData) {
                setShowToast(true);
                setDisabled(false);
            }
        }
        catch (error) { setDisabled(false); }
    };

    useEffect(() => { getEthnicityMealCategory() }, []);

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <Toast className="bg-success-subtle text-center" show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header className='d-flex justify-content-end'>
                    </Toast.Header>
                    <Toast.Body>Score Updated</Toast.Body>
                </Toast>
            </div>

            <form className='bg-light p-3'>
                <h1 className="h3 mb-3 fw-normal py-4 text-center">Update Score</h1>
                <div className="form-floating required py-4">
                    <input type="text" className={scoreValidator ? `form-control is-invalid` : "form-control"} value={score} id="score" onChange={(e) => setScore(e.target.value)} />
                    <label className="control-label" htmlFor="floatingInput">Score<small className='text-danger'>*</small></label>
                    {scoreValidator && (<p className='text-danger'>Score is required.</p>)}
                </div>
                <div className="form-floating py-4">
                    <textarea type="text" className="form-control" value={description} id="description" onChange={(e) => setDescription(e.target.value)} />
                    <label className='control-label' htmlFor="floatingInput">Describe Relationship</label>
                </div>

                <div className='row justify-content-center'>
                    <button className="btn btn-dark py-4 my-4 col-6" onClick={handleUpdate} disabled={disabled}>{purpose}</button>
                </div>
            </form>
        </div>
    )
}
