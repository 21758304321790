import React, { useState, useEffect } from 'react';
import TableWrapper from '../../components/table/table_wrapper';
import { NavLink } from 'react-router-dom';
import foodItemsService from '../../api/food_items_service';



export default function Items() {
    const [food_items, setFoodItems] = useState([]);

    if (food_items.length > 0) {
        food_items.map(data => data.name = data.names[0].name )
    }

    const getFoodItemsData = async () => {
        try {
            const getData = await foodItemsService.getFoodItems();
            if (getData) { setFoodItems(getData) }
        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { getFoodItemsData() }, []);

    food_items.sort(function(a, b) {
        // Convert names to lowercase for case-insensitive sorting
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        
        if (nameA < nameB) {
            return -1; // nameA comes before nameB
        }
        if (nameA > nameB) {
            return 1; // nameB comes before nameA
        }
        return 0; // names are equal
    });

    return (
        <div>
            <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                <NavLink to="/create-item" className="btn btn-dark">
                    Add Item
                </NavLink>
            </div>
            {food_items.length > 0 && < TableWrapper data={food_items} link="/view-item" />}
        </div>
    );
}